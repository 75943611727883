.footer-container {
  padding: 130px 0 20px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 30%);
  color: #ffffff;
}

.footer-container .wrapper {
  display: flex;
  justify-content: space-between;
}

.footer-column:nth-of-type(1) {
  flex: 3;
  margin-right: 100px;
}

.footer-column:nth-of-type(2) {
  flex: 2;
}

.footer-column:nth-of-type(3) {
  flex: 2;
}

.footer-container .logo {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  max-width: unset;
}

.footer-container .logo img {
  width: 50px;
  margin-right: 10px;
  margin-top: 5px;
}

.footer-icons {
  display: flex;
  gap: 20px;
  margin-top: 25px;
}

.footer-icons a img {
  width: 30px;
  opacity: 0.8;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 15px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-container h2 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}


@media (max-width: 785px) {
  .footer-column:nth-of-type(1) {
    flex: 2;
    margin-right: 70px;
  }
}


@media (max-width: 625px) {
  .footer-container .wrapper {
    flex-wrap: wrap;
  }

  .footer-column:nth-of-type(1) {
    min-width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
  }

  .footer-column:nth-of-type(1), .footer-column:nth-of-type(2), .footer-column:nth-of-type(3) {
    text-align: center;
  }

  .footer-container .logo {
    width: fit-content;
    margin: 0 auto;
  }

  .footer-icons {
    justify-content: center;
  }
}