canvas {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.canvas-div {
  position: absolute;
  width: 100%;
  text-align: center;
}
