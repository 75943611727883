* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
}

body {
    background-color: #000;
}

.wrapper {
    width: 1170px;
    max-width: 95vw;
    margin: 0 auto;
}

p {
    line-height: 1.5rem;
    color: rgba(217, 217, 217, 0.8);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
}

ul {
    list-style: none;
}

.app {
  font-family: Arial, sans-serif;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

nav {
    width: 100%;
}

nav ul, nav .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav a {
  margin: 0 20px;
  text-decoration: none;
  color: #fff;
}

.nav-list {
  transition: all 0.3s ease-out;
}

.nav-list .nav-list-link {
  text-shadow: 3px 3px 20px #000;
    position: relative;
    font-size: 18px;
    font-weight: 500;
}

.nav-list .nav-list-link::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: #fff;
    transition: width .5s ease;
}

.nav-list .nav-list-link:hover {
    cursor: pointer;
}

.nav-list .nav-list-link:hover::after {
    width: 100%;
}

.nav-list .button, .nav-list .logo {
  box-shadow: 3px 3px 20px #000;
}

.nav-list .disabled {
    color: gray;
    cursor: not-allowed;
    pointer-events: none;
}

.logo {
    display: flex;
    align-items: center;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 2px;
}

.logo img {
    width: 100px;
    margin-top: 20px;
}

a {
    text-decoration: none;
}


h1 {
    font-size: 3rem;
    line-height: 4.5rem;
    font-weight: 900;
}

h2 {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 700;
}

h3 {
    font-size: 1.7rem;
    line-height: 2.2rem;
    font-weight: 600;
}

h4 {
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 600;
}

.intro {
    position: relative;
    margin-top: 150px;
    padding: 20px;
    text-align: center;
    padding-bottom: min(350px, 50vh);
}

.intro h1 {
    text-shadow: 3px 3px 20px #000;
}

.intro .button {
    box-shadow: 3px 3px 20px #000;
}

.button {
  padding: 15px 30px;
  border: 2px solid #2c2c2c;
  background-color: #1a1a1a;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 30px;
  transition: all 0.4s ease;
  outline: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
  font-weight: bold;
}

.button.button-secondary {
  padding: 7px 15px;
  font-size: 1rem;
}

.button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0) 70%
  );
  transform: scale(0);
  transition: transform 0.5s ease;
}

.button:hover::after {
  transform: scale(4);
}

.button:hover {
  border-color: #666666;
  background: #292929;
}


.intro-buttons {
    display: flex;
    justify-content: center;
    margin: 0 auto;
}

.intro-buttons .button {
    width: 250px;
    padding: .7rem 1rem;
}

.intro-buttons .button:first-of-type {
    margin-right: 30px;
}

.title-desktop {
    display: block;
    margin-bottom: 30px;
}
.title-mobile {
    display: none;
    margin-bottom: 30px;
}

@media (max-width: 790px) {
  .nav-list {
    display: none;
  }

    nav .wrapper {
        justify-content: center;
    }



  .nav-list li {
    text-align: center;
  }

}


@media (max-width: 525px) {
    .title-desktop {
        display: none;
    }
    .title-mobile {
        display: block;
    }

    .intro {
        padding-top: 30px;
    }

    .intro-buttons {
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }

    .intro-buttons .button:first-of-type {
        margin-right: 0;
        margin-bottom: 10px;
    }
}
