.alternating-content {
  display: flex;
  flex-direction: column;
}

.content-section {
  display: flex;
  gap: 50px;
}

.content-section:not(:last-of-type) {
  margin-bottom: 150px;
}

.text-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(50% - 25px);
}

.text-content h2 {
  margin-bottom: 20px;
}

.text-content a {
  margin-top: 10px;
  max-width: fit-content;
  text-decoration: none;
}

.info-cards {
  flex: 1;
  background-color: #000;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  text-align: center;
  padding-bottom: 20px;
}

.info-cards_header {
  text-align: center;
  background-color: rgba(255, 255, 255, .8);
  color: #000;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 10px;
}

.info-cards_item {
  padding: 10px;
}

.text-content-links  {
  margin-top: 20px;
}

.text-content-links a:not(:last-of-type) {
  margin-right: 20px;
}

@media (max-width: 890px) {
  .content-section {
    width: 80%;
    margin: 0 auto;
  }

  .content-section:nth-child(1), .content-section:nth-child(3) {
      flex-direction: column;
  }

  .content-section:nth-child(2),.content-section:nth-child(4) {
      flex-direction: column-reverse;
  }

  .text-content {
      width: 100%;
  }

  .content-section .info-cards {
    display: none;
  }
}

@media (max-width: 600px) {
  .content-section {
    width: 95%;
  }
}

.cards-inner {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5em;
}

.card-bullets {
  line-height: 1.4;
}

.card-bullets li::before {
  display: inline-block;
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='16' title='check' fill='%23dddddd'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z' /%3E%3C/svg%3E");
  transform: translatey(0.25ch);
  margin-right: 1ch;
}

.flow > * + * {
  margin-top: var(--flow-space, 1.25em);
}

.hover-overlay .info-card {
  background-color: hsla(var(--hsl), 0.15);
  border-color: hsla(var(--hsl), 1);
  box-shadow: 0 0 0 1px inset hsl(var(--hsl));
}

.hover-overlay .cta {
  display: block;
  grid-row: -1;
  width: 100%;
  background-color: hsl(var(--hsl));
  box-shadow: 0 0 0 1px hsl(var(--hsl));
}
