.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

.cards {
  position: relative;
}

.cards__inner {
  display: flex;
  flex-wrap: wrap;
  gap: 2.5em;
  margin-bottom: 2em; /* Adjust spacing between rows */
}

.card {
  --flow-space: 0.5em;
  --hsl: var(--hue), var(--saturation), var(--lightness);
  flex: 1 1 20rem;
  padding: 1.5em 2em;
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  align-items: start;
  gap: 1.25em;
  color: #eceff1;
  background-color: #2b2b2b;
  border: 1px solid #eceff133;
  border-radius: 15px;
}

.small-cards .small-card {
  --flow-space: 0.5em;
  flex: 1 1 15rem;
  padding: 1em 1.5em; /* Smaller padding for small cards */
  display: grid;
  grid-template-rows: auto auto;
  align-items: start;
  background-color: #2b2b2b;
  border: 1px solid #eceff133;
  border-radius: 15px;
}

.card:nth-child(1) {
  --hue: 165;
  --saturation: 82.26%;
  --lightness: 51.37%;
}

.card:nth-child(2) {
  --hue: 291.34;
  --saturation: 95.9%;
  --lightness: 61.76%;
}

.card:nth-child(3),
.card:nth-child(4) {
  --hue: 0;
  --saturation: 0%;
  --lightness: 80%;
}

.card__heading {
  font-size: 1.05em;
  font-weight: 600;
}

.card__heading a {
  display: inline;
}

.flow > * + * {
  margin-top: var(--flow-space, 1.25em);
}

.overlay {
  position: absolute;
  inset: 0;
  pointer-events: none;
  user-select: none;
  opacity: var(--opacity, 0);
  mask: radial-gradient(
    25rem 25rem at var(--x) var(--y),
    #000 1%,
    transparent 50%
  );
  transition: mask 0.4s ease, opacity 0.4s ease;
  display: flex;
  flex-wrap: wrap;
}

.overlay .card {
  background-color: rgba(204, 0, 0, 0.5); /* Red overlay */
  border: none;
  box-shadow: 0 0 0 1px inset rgba(204, 0, 0, 1); /* Red inset shadow */
  height: 100%;
}

.overlay .card:nth-child(1), .overlay .card:nth-child(2) {
  min-width: calc(50% - 20px) !important;
  max-width: calc(50% - 20px) !important;
}

.overlay .card:nth-child(4), .overlay .card:nth-child(3) {
  margin-top: 38px;
  min-width: calc(50% - 20px) !important;
  max-width: calc(50% - 20px) !important;
}

.overlay .card:nth-child(6), .overlay .card:nth-child(5) {
  margin-top: -8px;
  min-width: calc(50% - 20px) !important;
  max-width: calc(50% - 20px) !important;
}

.soon-label {
  background-color: red;
  color: white;
  padding: 0.2em 0.5em 0.3em;
  border-radius: 5px;
  font-size: 0.8rem;
}

.small-cards {
  text-align: center;
}

.small-cards .small-card {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 715px) {
  .overlay .card:nth-child(4), .overlay .card:nth-child(3) {
    margin-top: 38px;
  }

  .overlay .card:nth-child(6), .overlay .card:nth-child(5) {
    margin-top: -8px;
  }
}

@media (max-width: 690px) {
  .small-cards .small-card {
    margin-top: 0;
    min-width: 100%;
    max-width: 100%;
  }

  .overlay .card:nth-child(1), .overlay .card:nth-child(2), .overlay .card:nth-child(3), .overlay .card:nth-child(4), .overlay .card:nth-child(5), .overlay .card:nth-child(6) {
    min-width: 100%;
    max-width: 100%;
  }

  .overlay .card:nth-child(4) {
    transform: translateY(-40px);
  }

  .overlay .card:nth-child(5) {
    transform: translateY(-40px);
  }

  .overlay .card:nth-child(6) {
    transform: translateY(-30px);
  }
}